import React, { FunctionComponent } from 'react';
import { classNames } from '../../../utilities/classNames';

interface Props {
  label: string;
  formName: string;
  required?: boolean;
  value?: string;
  small?: boolean;
  short?: boolean;
  helpText?: string | null;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  className?: string;
}

/**
 * a basic textarea with the label on the left
 * from the tailwind form layout https://tailwindui.com/components/application-ui/forms/textareas#component-4dfa34096e750fe0cc9a5086286bc441
 */
export const BasicTextAreaInput: FunctionComponent<Props> = ({
  label,
  formName,
  required,
  value,
  small,
  short,
  helpText,
  onChange,
  className,
}) => {
  return (
    <div
      className={
        small
          ? undefined
          : 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'
      }
    >
      <label
        htmlFor={formName}
        className={classNames(
          'block text-sm font-medium text-gray-700',
          !small && 'sm:mt-px sm:pt-2',
        )}
      >
        {label} {required && '*'}
      </label>
      <div className={classNames('mt-1', !small && 'sm:mt-0 sm:col-span-2')}>
        <textarea
          name={formName}
          id={formName}
          onChange={onChange}
          required={required}
          defaultValue={value}
          rows={short ? 1 : small ? 3 : 4}
          className={classNames(
            'block w-full focus:ring-accent-500 focus:border-accent-500 border-gray-300 rounded-lg',
            !small && 'max-w-lg sm:max-w-xs',
            className,
            className?.match(/text-[xs|sm|md|xl]/) ? '' : 'text-sm',
          )}
        />
        {helpText && (
          <p className="mt-2 text-sm text-gray-500 font-normal">{helpText}</p>
        )}
      </div>
    </div>
  );
};

BasicTextAreaInput.defaultProps = {
  required: false,
  value: '',
  helpText: null,
};
