import { ColorDot } from '../../components/Common/ColorDot';
import { Color } from '../../typings/backend-types';

export function withColorLabel(
  text: string,
  label: Color | null | undefined,
  large?: boolean,
): JSX.Element | null {
  return (
    <span className="inline-flex items-center">
      <span className={large ? 'mr-2' : 'mr-1.5'}>{text}</span>
      <ColorDot color={label} large={large} />
    </span>
  );
}
